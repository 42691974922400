import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import { Button, Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { SHIFT_BLOCKS_PATH } from "@src/appV2/ShiftBlocks/paths";
import { SHIFT_BLOCK_ACTIONS } from "@src/appV2/ShiftBlocks/utils";
import {
  ShiftColleagues,
  ShiftColleaguesContext,
} from "@src/appV2/Shifts/FacilityColleagues/ShiftColleagues";
import { ShiftSpecificationAlert } from "@src/appV2/Shifts/Shift/ShiftSpecification/ShiftSpecificationAlert";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { StreakAlertWrapper } from "@src/appV2/Streaks/components/StreakAlertWrapper";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isToday, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { generatePath, useRouteMatch } from "react-router-dom";

import { CardHeader } from "./CardHeader";
import { FacilityDetails } from "./FacilityDetails";

interface ShiftCardProps {
  shift: Shift;
  worker: Worker;
  chatChannels: SendBird.GroupChannel[];
}

export function ShiftCard(props: ShiftCardProps) {
  const { shift, worker, chatChannels } = props;
  const { path: currentRouterUrl } = useRouteMatch();

  const isShiftToday = isToday(parseISO(shift.start));

  return (
    <Card variant="outlined" data-testid={`my-shift-card-${shift._id}`}>
      <CardActionArea
        component={InternalLink}
        to={generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
          shiftId: shift._id,
        })}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.SHIFT_CARD_CLICKED, {
            source: "card-tap",
            shiftId: shift._id,
            agentId: worker._id,
          });
        }}
      >
        <CardContent
          sx={{
            paddingX: 3,
            paddingY: 2,
            "&:last-child": {
              paddingBottom: 2,
            },
          }}
        >
          <Stack spacing={2}>
            <CardHeader shift={shift} worker={worker} />
            <Stack spacing={1}>
              <Stack spacing={2}>
                {isDefined(shift.start) &&
                  isDefined(shift.end) &&
                  isDefined(worker?.userId) &&
                  isDefined(shift.facility?.userId) && (
                    <StreakAlertWrapper
                      onlyCheckActiveStreak
                      shiftStartTime={parseISO(shift.start)}
                      shiftEndTime={parseISO(shift.end)}
                      workerId={worker.userId}
                      workplaceId={shift.facility.userId}
                      shiftId={shift._id}
                    />
                  )}
                <FacilityDetails
                  worker={worker}
                  facility={shift.facility}
                  chatChannels={chatChannels}
                  onClickChatButton={() => {
                    logEvent(APP_V2_USER_EVENTS.SHIFT_CARD_CHAT_BUTTON_CLICKED, {
                      shiftId: shift._id,
                      agentId: worker._id,
                      facilityId: shift.facility.userId,
                      location: currentRouterUrl,
                    });
                  }}
                />
                <ShiftColleagues context={ShiftColleaguesContext.MY_SHIFTS} shift={shift} />
              </Stack>

              {isDefined(shift.facility.checkInInstructions) &&
              !isEmpty(shift.facility.checkInInstructions.trim()) &&
              isShiftToday ? (
                <Stack spacing={2}>
                  <Stack>
                    <Text variant="h4">Check-in instructions</Text>
                    <Text variant="caption">{shift.facility.checkInInstructions}</Text>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
            <Stack alignItems="start" spacing={2}>
              {isDefined(shift?.shiftBlockId) && (
                <InternalLink
                  underline="always"
                  to={generatePath(
                    `${RootPaths.APP_V2_HOME}/${SHIFT_BLOCKS_PATH}/:shiftBlockId/:agentId`,
                    {
                      shiftBlockId: shift.shiftBlockId ?? "",
                      agentId: worker.userId,
                    }
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isDefined(shift.shiftBlockId)) {
                      logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                        action: SHIFT_BLOCK_ACTIONS.VIEW_BLOCK_DETAILS_TAPPED,
                        shiftBlockId: shift.shiftBlockId,
                        facilityId: shift.facility.userId,
                        location: currentRouterUrl,
                      });
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <AutoAwesomeMotionOutlinedIcon
                      fontSize="small"
                      sx={{
                        transform: "scaleX(-1)",
                      }}
                    />
                    <span>View all shifts in block</span>
                  </Stack>
                </InternalLink>
              )}
              {isDefined(shift.shiftSpecifications) && shift.shiftSpecifications.length > 0 && (
                <Stack width="100%">
                  <ShiftSpecificationAlert shiftSpecifications={shift.shiftSpecifications[0]} />
                </Stack>
              )}
              <Button
                fullWidth
                size="small"
                variant="contained"
                component={InternalLink}
                to={generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
                  shiftId: shift._id,
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  logEvent(APP_V2_USER_EVENTS.SHIFT_CARD_CLICKED, {
                    source: "button-tap",
                    shiftId: shift._id,
                    agentId: worker._id,
                  });
                }}
              >
                View Shift Details
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
